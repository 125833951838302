/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    ol: "ol"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "care-tips-for-rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#care-tips-for-rechargeable-hearing-aids",
    "aria-label": "care tips for rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Care Tips for Rechargeable Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s no doubt that ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "rechargeable hearing aids"), " are experiencing a boom in popularity. In fact, over half of hearing aid wearers now use rechargeable devices. And for good reason. Batteries, such as the lithium-ion rechargeable, now last longer than ever, giving a hearing aid wearer a full-day continuous wear time before it needs to be recharged."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, what’s behind the hype? With rechargeable electronics like smartphones and earbuds being the norm, people have become more accustomed to and comfortable with rechargeable devices in general. And since rechargeable hearing aids have come a long way over the past few years, hearing aid wearers are reaping the benefits, such as:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Freedom from spending money constantly on batteries and changing them every 2-3 days."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ease of use for those with dexterity issues, which make it hard to change out small batteries."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Less waste and friendlier to the environment, due to not throwing out batteries every couple days."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Convenience of fully charging their hearing aids overnight, and not having to always carry around extra packs of batteries."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Safety, as small batteries can easily be swallowed — a danger to children and animals."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "types-of-rechargeable-hearing-aids-and-how-to-keep-them-healthy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-rechargeable-hearing-aids-and-how-to-keep-them-healthy",
    "aria-label": "types of rechargeable hearing aids and how to keep them healthy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of rechargeable hearing aids and how to keep them healthy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are two types of rechargeable hearing aid batteries:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Lithium-ion – Hearing aids with this type of battery do not have a battery door. Lithium-ion batteries normally last as long as the hearing aid itself, which is usually around 4-5 years."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Silver-Zinc – Hearing aids with this type of battery, also known as a Z-power battery, have a battery door. Silver-Zinc batteries last about a year and can be replaced by the manufacturer."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Keep your rechargeable hearing aid batteries healthy by:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Always placing your hearing aids into a plugged-in charging station when you’re not wearing them. A charger that’s not plugged in will drain them and the overall rechargeable hearing aid battery life."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turning them off if you take them out and are unable to get them on the charger right away."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For hearing aids with a battery door, be sure to:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Keep the battery door closed when you take off your devices and charge them."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean your devices and the battery compartment. Open the battery door and remove the batteries. Place them on a soft cloth to keep them safe until you’re finished."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both types batteries take about 4 hours to charge and give you up to 30 hours of wear time. Charging, wear time and care may fluctuate between hearing aids brands, so be sure to check your hearing aid manual for information."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "additional-rechargeable-hearing-aids-care-tips",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#additional-rechargeable-hearing-aids-care-tips",
    "aria-label": "additional rechargeable hearing aids care tips permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Additional rechargeable hearing aids care tips"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Standard rechargeable hearing aid maintenance is similar to taking care of any other hearing aid. Here are three important tips."), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Watch out for earwax."), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Earwax is a fact of life, and will occasionally appear on your devices. Cleaning them properly as directed will keep them free of earwax and operating their best."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Wipe them off every day with a soft cloth, and be sure to clean the tubing, if applicable. Cleaning them when wax is dry is recommended, as it’s easier to remove."), "\n"), "\n", React.createElement(_components.ol, {
    start: "2",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Don’t take them swimming."), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Take them out when you’re showering or swimming."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Store them away from the water, preferably somewhere cool, to prevent condensation."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If your hearing aids accidentally get submerged in water, contact your audiologist or hearing care provider immediately."), "\n"), "\n", React.createElement(_components.ol, {
    start: "3",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Keep them safe from damage."), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Never take your hearing aids out and leave them lying around. Store them according to the manufacturer's directions. Your hearing care specialist can also provide guidance if you need it."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Keep them away from children and pets."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Be sure to talk with your hearing care professional about how to best take care of your rechargeable hearing aids, so they’ll last you for years to come."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-rechargeable-hearing-aids-need-a-little-extra-tlc",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-rechargeable-hearing-aids-need-a-little-extra-tlc",
    "aria-label": "why rechargeable hearing aids need a little extra tlc permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why rechargeable hearing aids need a little extra TLC"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are an investment, so you want them to last as long as possible. Taking time to properly care for rechargeable hearing aids and batteries is vital to keep them functioning at their best for longer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember –— Be sure to always charge your hearing aids to their full level. At the end of the day when you remove them, get in the habit of cleaning them to get rid of dirt and debris. Then place them in the charging station afterward so they’re ready to go for you the next day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Be sure to follow the instructions for your specific hearing aid and battery so you maximize the life of your devices."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist-from-hearcom",
    "aria-label": "contact a hearing specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a Hearing Specialist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re ready to learn more or invest in rechargeable hearing aids for a better life, we’re here for you. We invite you to join our 45-day no risk trial to try the latest rechargeable hearing aids in your everyday life. Simply complete the brief questionnaire, then answer the phone when we call to get all your questions answered. We’re ready to help you get back to hearing and living your best."), "\n", React.createElement(LandingPageCta, {
    copy: "Join the 45-day Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
